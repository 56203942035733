import { FC } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import BoxedSignBW from '@/components/boxedSignBW';

import AndroidStoreLogo from './components/android';
import AppleStoreLogo from './components/apple';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const Logos: FC<Props> = ({ className }) => (
  <div
    {...{
      className: cx(styles.container, className),
    }}
  >
    <BoxedSignBW />

    <div
      {...{
        className: styles.logos,
      }}
    >
      <AppleStoreLogo />

      <AndroidStoreLogo />
    </div>
  </div>
);

export default Logos;
