export const container = "c19xtpi";
export const heading = "hza0xtn";
export const mainContent = "mlq9j86";
export const linkButton = "l1cbz9ss";
export const mainLogos = "m1dns06s";
export const mainText = "mpt3lzg";
export const actionButton = "a7rs370";
export const footer = "foba0yd";
export const firstBlock = "f8rpmf0";
export const blockIllustration = "bn13ns1";
export const secondBlockIllustration = "sidck1u";
export const textWrapper = "t18lj3th";
export const secondBlock = "s1c03xi1";
export const ulList = "u1twt556";
export const flexVCentered = "fsvvvjz";
export const noGap = "n1ag832o";
export const joinButtonWrapper = "jcj7www";

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");