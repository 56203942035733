import { FC } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const BoxedSignBW: FC<Props> = ({ className }) => (
  <div
    {...{
      className: cx(styles.boxedSign, className),
    }}
  >
    <img
      {...{
        src: '/images/mentors/churchLogo.png',
        alt: 'OurRoad',
        width: 94,
        height: 94,
      }}
    />
  </div>
);

export default BoxedSignBW;
