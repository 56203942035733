import type { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';

import MainPageNewContainer from '@/containers/mainPageNew';

import MainPageNewLayout from '@/layouts/mainPageNew';
import { getNameSpaces } from '@/utils/translationSSR';

const Page: NextPage = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Head>
        <title>{t('title')}</title>
      </Head>

      <MainPageNewLayout>
        <MainPageNewContainer />
      </MainPageNewLayout>
    </>
  );
};

// нет перевода на корейский, так что пока фиксируем английскую локаль
export const getServerSideProps = getNameSpaces(['main'], 'en');

export default Page;
