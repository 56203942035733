const australiaStates: string[] = [
  'New South Wales',
  'Queensland',
  'South Australia',
  'Tasmania',
  'Victoria',
  'Western Australia',
  'Northern Territory',
  'Australian Capital Territory',
  'Jervis Bay Territory',
  'Norfolk Island',
  'Christmas Island',
  'Cocos Islands',
  'Ashmore and Cartier Islands',
  'Coral Sea Islands Territory',
  'Heard Island and McDonald Islands',
  'Australian Antarctic Territory',
];

export const states: Record<string, string[]> = {
  AU: australiaStates,
};
