import { FC, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cx } from '@linaria/core';

import { promoMentorUUID } from '@/constants';
import { creatHrefWithUTM } from '@/helpers/withUTM';

import Page from '@/layouts/mainPageNew/components/page';

import Logos from './components/logos';

import * as styles from './styles';

const joinNowLink = `/mentors/${promoMentorUUID}`;

const MainPageNewContainer: FC = () => {
  const router = useRouter();
  const { t } = useTranslation(['main', 'common']);

  useEffect(() => {
    window?.fbq?.('track', 'ViewContent');
  }, []);

  return (
    <>
      <Page>
        <div
          {...{
            className: styles.mainContent,
          }}
        >
          <Logos
            {...{
              className: styles.mainLogos,
            }}
          />

          <h1
            {...{
              className: styles.mainText,
            }}
          >
            {t('content.welcomeText.p1')}
            <br />
            {t('content.welcomeText.p2')}
          </h1>

          <div className={styles.firstBlock}>
            <div className={styles.textWrapper}>
              <p>{t('content.paragraph1.p1')}</p>
              <p>{t('content.paragraph1.p2')}</p>
              <p>{t('content.paragraph1.p3')}</p>
            </div>
            <div>
              <img
                className={styles.blockIllustration}
                src={'/images/landing/1.jpg'}
                width={535}
                height={425}
                alt={'Road'}
              />
            </div>
          </div>

          <div className={styles.joinButtonWrapper}>
            <Link
              {...{
                href: creatHrefWithUTM(router, joinNowLink),
              }}
            >
              <a
                {...{
                  className: cx(styles.actionButton, styles.linkButton),
                }}
              >
                {t('common:joinNow')}
              </a>
            </Link>
          </div>

          <div className={styles.secondBlock}>
            <h1
              {...{
                className: styles.mainText,
              }}
            >
              {t('content.paragraph2.title')}
            </h1>
            <div className={styles.textWrapper}>
              <p>{t('content.paragraph2.p1')}</p>
              <p>{t('content.paragraph2.p2')}</p>
              <p>{t('content.paragraph2.p3')}</p>
              <p>
                <img
                  className={styles.secondBlockIllustration}
                  src={'/images/landing/2.jpg'}
                  width={1148}
                  height={534}
                  alt={'Road'}
                />
              </p>
              <p>{t('content.paragraph2.p4.begin')}</p>
              <ul className={styles.ulList}>
                <li>{t('content.paragraph2.p4.point1')}</li>
                <li>{t('content.paragraph2.p4.point2')}</li>
                <li>{t('content.paragraph2.p4.point3')}</li>
              </ul>
              <p>{t('content.paragraph2.p4.end')}</p>
              <p>{t('content.paragraph2.p5')}</p>
            </div>
          </div>

          <div className={styles.joinButtonWrapper}>
            <Link
              {...{
                href: creatHrefWithUTM(router, joinNowLink),
              }}
            >
              <a
                {...{
                  className: cx(styles.actionButton, styles.linkButton),
                }}
              >
                {t('common:joinNow')}
              </a>
            </Link>
          </div>

          <h1
            {...{
              className: styles.mainText,
            }}
          >
            {t('content.paragraph3.title')}
          </h1>

          <div className={styles.firstBlock}>
            <div className={styles.textWrapper}>
              <p>{t('content.paragraph3.p1')}</p>
              <ul className={styles.ulList}>
                <li>{t('content.paragraph3.point1')}</li>
                <li>{t('content.paragraph3.point2')}</li>
                <li>{t('content.paragraph3.point3')}</li>
              </ul>
              <p>{t('content.paragraph3.p2')}</p>
              <p>{t('content.paragraph3.p3')}</p>
            </div>
            <div className={styles.flexVCentered}>
              <img
                className={styles.blockIllustration}
                src={'/images/landing/3.jpg'}
                width={535}
                height={425}
                alt={'Road'}
              />
            </div>
          </div>
          <div className={styles.joinButtonWrapper}>
            <Link
              {...{
                href: creatHrefWithUTM(router, joinNowLink),
              }}
            >
              <a
                {...{
                  className: cx(styles.actionButton, styles.linkButton),
                }}
              >
                {t('common:joinNow')}
              </a>
            </Link>
          </div>
        </div>
      </Page>
    </>
  );
};

export default MainPageNewContainer;
