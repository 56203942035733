import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const HeadingMobileMenu: FC<PropsWithChildren<Props>> = ({
  className,
  children,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const { body } = document;

    if (open) {
      body.classList.add(styles.overflow);
    } else {
      body.classList.remove(styles.overflow);
    }
  }, [open]);

  return (
    <div
      {...{
        className: cx(styles.container, className),
      }}
    >
      <button
        {...{
          type: 'button',
          className: styles.buttonIcon,
          onClick: () => {
            setOpen(!open);
          },
        }}
      >
        {open ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.51465 20.4853L20.4852 3.51471"
              stroke="#242933"
              strokeWidth="2"
            />
            <path
              d="M3.51465 3.51471L20.4852 20.4853"
              stroke="#242933"
              strokeWidth="2"
            />
          </svg>
        ) : (
          <svg
            width="24"
            height="14"
            viewBox="0 0 24 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 6H24V8H0V6Z" fill="#242933" />
            <path d="M0 0H24V2H0V0Z" fill="#242933" />
            <path d="M0 12H24V14H0V12Z" fill="#242933" />
          </svg>
        )}
      </button>

      <div
        {...{
          className: cx(styles.menu, open && styles.menuOpen),
        }}
      >
        <div
          {...{
            className: styles.menuInner,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default HeadingMobileMenu;
