import { FC, PropsWithChildren } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import Footer from './components/footer';
import Heading from './components/heading';

import * as styles from './styles';
import './styles.global';

interface Props {
  withHeading?: boolean;
  withFooter?: boolean;
  withHeadingMenu?: boolean;
  className?: LinariaClassName;
  footerClassName?: LinariaClassName;
}

const MainPageNewLayout: FC<PropsWithChildren<Props>> = ({
  children,
  withHeading = true,
  withFooter = true,
  withHeadingMenu = true,
  footerClassName,
  className,
}) => (
  <>
    {withHeading && (
      <Heading
        {...{
          withHeadingMenu,
          className: styles.heading,
        }}
      />
    )}
    <div
      {...{
        className: cx(styles.mainPage, className),
      }}
    >
      <div
        {...{
          className: styles.mainPageInner,
        }}
      >
        <div
          {...{
            className: styles.contentContainer,
          }}
        >
          {children}
        </div>
      </div>
    </div>
    {withFooter && (
      <Footer
        {...{
          className: cx(footerClassName),
        }}
      />
    )}
  </>
);

export default MainPageNewLayout;
