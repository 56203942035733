import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { cx, LinariaClassName } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const HeadingMenuList: FC<Props> = ({ className }) => {
  const { t } = useTranslation('main');

  return (
    <nav
      {...{
        className: cx(styles.menu, className),
      }}
    >
      <ul>
        {/*<li>*/}
        {/*  <Link*/}
        {/*    {...{*/}
        {/*      href: '/mentors/become',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <a*/}
        {/*      {...{*/}
        {/*        href: '/mentors/become',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {t('menu.about')}*/}
        {/*    </a>*/}
        {/*  </Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link*/}
        {/*    {...{*/}
        {/*      href: '/mentors/become',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <a*/}
        {/*      {...{*/}
        {/*        href: '/mentors/become',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {t('menu.become')}*/}
        {/*    </a>*/}
        {/*  </Link>*/}
        {/*</li>*/}

        {/*<li>*/}
        {/*  <Link*/}
        {/*    {...{*/}
        {/*      href: '/help',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <a*/}
        {/*      {...{*/}
        {/*        href: '/help',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {t('menu.support')}*/}
        {/*    </a>*/}
        {/*  </Link>*/}
        {/*</li>*/}

        <li>
          <Link
            {...{
              href: '/account',
            }}
          >
            <a
              {...{
                href: '/account',
                className: styles.button,
              }}
            >
              {t('common:login')}
            </a>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default HeadingMenuList;
